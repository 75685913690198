<template>
    <div class="wrapper">
       <div>
          <div class="logo">
           <img src="..\assets\img\logo有色备份@2x.png">
          </div>
          <div class="wrapper__form1">
         <div class="phone">
           <img src="..\assets\img\手机号.png">
         </div>
         <div class="wrapper__input">
            <input class="wrapper__input__content"
              placeholder="请输入手机号"
              type="text"
              v-model="data.phoneNumber"
              maxlength="11"
            >
         </div>
          </div>
          <div class="form1_solid"></div>
          <div class="wrapper__form2">
         <div class="lock">
           <img src="..\assets\img\开锁换锁.png">
         </div>
         <div class="wrapper__input">
            <input class="wrapper__input__content"
              placeholder="请输入短信验证码"
              type="text"
              v-model="data.code"
              maxlength="6"
            >
         </div>
         <button class="getver"  @click="send" :disabled="disabled">{{text}}</button>
          </div>
          <div class="form2_solid"></div>
          <div class="wrapper-active">
            <div class="check" @click="handleAutoLogin">
              <img :src=showImg>
            </div>
            <div class="active-content">我同意<span @click="select('read')">《用户协议及隐私条款》</span></div>
          </div>
          <div class="wrapper__button" @click="handleSubmit"
          :style="{'backgroundColor': buttonType ? '#3974FB' : '#A7BFF5'}"
          >立即注册</div>
          <div class="wrapper__has">
              <div class="has1">已有账号，</div>
              <div class="has2" @click="select('login')">立即登录</div>
          </div>
       </div>
    </div>
</template>

<script>
import selectButton from '../components/getCode'
import { reactive, computed } from 'vue'
import { postForm } from '../service/base'
import { useRouter } from 'vue-router'

export default {
  name: 'newuser',
  setup () {
    const { text, disabled, countTime } = selectButton()
    const router = useRouter()
    const data = reactive({ phoneNumber: '', code: '', know: true })

    // 页面跳转
    const select = (a) => { router.push(a) }

    // 定义按钮颜色样式
    const buttonType = computed(() => {
      return !!(data.phoneNumber.length === 11 && data.code.length === 6 && disabled.value)
    })

    // 按钮样式
    const showImg = computed(() => {
      return data.know ? require('@/assets/img/选中.png') : require('@/assets/img/未选中.png')
    })

    // 控制是否同意用户协议
    const handleAutoLogin = () => {
      data.know = !data.know
    }

    // 发送验证码
    const send = async () => {
      var formData = new FormData()
      formData.append('phoneNumber', data.phoneNumber)
      formData.append('key', 'a5b4c18cc837456092804e400b5019c9')
      try {
        await postForm('/home/system/send_verify', formData)
        if (data.phoneNumber) {
          if ((/^1[3|4|5|8][0-9]\d{4,8}$/.test(data.phoneNumber))) {
            alert('验证码发送成功')
            countTime()
          } else {
            alert('手机号不正确')
          }
        } else {
          alert('手机号不能为空')
        }
      } catch (e) {
        console.log(e)
      }
    }

    // 注册账号
    const handleSubmit = async () => {
      var formData = new FormData()
      formData.append('productName', data.name)
      formData.append('phoneNumber', data.phoneNumber)
      formData.append('messageCode', data.code)
      try {
        if (data.phoneNumber && data.code) {
          if (data.know) {
            const result = await postForm('/home/freeuser/registerByH5', formData)
            if (result.data.code === 200) {
              alert('注册成功')
            } else if (result.data.code === 404) {
              alert('该手机号已经被注册')
            } else {
              alert(result.data.message)
            }
          } else {
            alert('请先同意用户协议及隐私条款')
          }
        } else {
          alert('必填项不能为空')
        }
      } catch (e) {
        console.log(e)
      }
    }
    return {
      data,
      text,
      disabled,
      send,
      handleSubmit,
      handleAutoLogin,
      showImg,
      select,
      buttonType
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.logo {
  text-align: center;
  width: 1.5rem;
  margin: 0.4rem auto 0.58rem auto;
}
img {
  width: 100%
}
.wrapper__form1 {
  position: relative;
  display: flex;
  line-height: 0.24rem;
  margin: 0 0.2375rem 0 0.2375rem;
  .phone {
    margin-top: 0.08rem;
    width: 0.1192rem;
    margin-right: 0.18rem;
  }
  .wrapper__input {
    flex: 1;
    box-sizing: border-box;
    .wrapper__input__content {
      font-size: 0.16rem;
      line-height: 0.24rem;
      width: 100%;
      border: none;
      outline: none;
      background: none;
    }
  }
}
.wrapper__form2 {
  display: flex;
  line-height: 0.24rem;
  margin: 0 0.2375rem 0 0.2375rem;
  .lock {
  margin-top: 0.08rem;
  width: 0.115rem;
  margin-right: 0.15rem;
  }
  .wrapper__input {
    width: 1.28rem;
    margin-right: 0.75rem;
    box-sizing: border-box;
    .wrapper__input__content {
      font-size: 0.16rem;
      line-height: 0.24rem;
      border: none;
      outline: none;
      background: none;
    }
  }
  .getver {
    font-size: 0.13rem;
    width: 0.92rem;
    height: 0.24rem;
    margin-top: 0.05rem;
    border-radius: 0.02rem;
    border: 0.005rem solid rgba(30, 30, 30, 1);
    text-align: center;
    box-sizing: border-box;
    background-color: #ffffff;
  }
}
.form1_solid {
  width: 3.275rem;
  height: 0.005rem;
  border: 0.005rem solid rgba(233, 233, 233, 1);
  margin: 0.12rem 0 0.13rem 0.2375rem;
}
.form2_solid {
  width: 3.275rem;
  height: 0.005rem;
  border: 0.005rem solid rgba(233, 233, 233, 1);
  margin: 0.14rem 0 0.195rem 0.2rem;
}
.wrapper-active {
  display: flex;
  align-items: center;
  margin-left: 0.24rem;
  .check {
    padding-right: 0.15rem;
    width: 0.16rem;
    padding-top: 0.03rem;
  }
  .active-content {
    font-size: 0.16rem;
    font-family: PingFangSC-Regular;
    color: #666666;
  }
}
.wrapper__button {
  line-height: 0.4rem;
  margin: 0.29rem 0.2rem 0.16rem 0.2rem;
  border-radius: 0.04rem;
  font-size: 0.14rem;
  font-family: PingFangSC-Medium;
  text-align: center;
  color: #ffffff;
}
.wrapper__has{
  text-align: center;
  font-size: 0.13rem;
  font-family: PingFangSC-Regular;
  display: flex;
  justify-content: center;
  .has1 {
    color: #666666;
  }
  .has2 {
    color: #3974FB;
  }
}
::-webkit-input-placeholder {
      color: #CFCFCF
    }
</style>
